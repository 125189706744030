<template>
    <header>
        <!-- <div id="tb-sticky-nav" class="container-fluid d-flex flex-row flex-wrap align-items-center justify-content-between px-3 py-2 m-0">
            <b-link href="/">
                <div class="brand-logo d-flex align-items-center">
                    <vuexy-logo/>
                    <h2 class="brand-text text-primary ml-1 mb-0 d-none d-md-block">{{ $appName }}</h2>
                </div>
            </b-link>

            <b-navbar-nav class="bookmark-wrapper nav d-flex flex-row align-items-center justify-content-between">
                <dark-toggler class="px-1"/>
                <cart-dropdown v-if="showCart" :isFromLanding="true" class="px-1"/>
                <b-nav-item class="px-1">
                    <b-link class="text-body transition-link" to="login">
                        <div class="d-flex align-items-center">
                            <feather-icon size="21" icon="LogInIcon" variant="info"/>
                        </div>
                    </b-link>
                </b-nav-item>
            </b-navbar-nav>
        </div> -->

        <!-- Red navbar -->
        <div class="header-top theme1 bg-red py-15">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-7 order-last order-md-first"></div>
                    <div class="col-lg-6 col-md-5">
                        <nav class="navbar-top pb-2 pb-md-0 position-relative">
                            <ul class="d-flex justify-content-center justify-content-md-end align-items-center mb-0">
                                <li>
                                    <a href="javascript:void(0)" id="dropdown1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Setting <i class="ion ion-ios-arrow-down"></i></a>
                                </li>

                                <li>
                                    <a href="javascript:void(0)" id="dropdown2" class="pr-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Branch <i class="ion- ion-ios-arrow-down"></i></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <!-- Red navbar -->

        <!-- Yellow navbar -->
        <div class="header-middle pt-20 pb-20">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-sm-6 col-lg-2 order-first">
                        <div class="logo text-center text-sm-left mb-30 mb-sm-0">
                            <a href="/"><img src="@/assets/img/logo/logo-dark.jpg" alt="logo"/></a>
                        </div>
                    </div>

                    
                    <div class="col-sm-6 col-lg-5 col-xl-4">
                        <div class="d-flex align-items-center justify-content-between justify-content-sm-between">
                            <div class="media static-media mr-50 d-none d-lg-flex" style="margin-right: 140px;">
                                <img class="mr-3 align-self-center" src="@/assets/img/icon/1.png" alt="icon"/>
                                <div class="media-body">
                                    <div class="phone">
                                        <span class="text-muted">Call us:</span>
                                    </div>
                                    <div class="phone">
                                        <a href="tel:(+02)4567890" class="text-dark">+(02)4567890</a>
                                    </div>
                                </div>
                            </div>

                            <!-- Sidenavs -->
                            <div class="cart-block-links theme1">
                                <ul class="d-flex flex-row align-items-center my-0">
                                    <li>
                                        <a class="offcanvas-toggle" href="javascript:void(0)">
                                            <span class="position-relative">
                                                <i class="icon-heart"></i>
                                            </span>
                                        </a>
                                    </li>

                                    <li>
                                        <a class="offcanvas-toggle" href="javascript:void(0)">
                                            <span class="position-relative">
                                                <i class="icon-bag"></i>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <!-- Mobile toggle -->
                            <div class="mobile-menu-toggle theme1 d-lg-none">
                                <a href="javascript:void(0)" class="offcanvas-toggle">
                                    <svg viewbox="0 0 800 600">
                                        <path
                                            d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                                            id="top"></path>
                                        <path d="M300,320 L540,320" id="middle"></path>
                                        <path
                                            d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                                            id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318)">
                                        </path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>

                    <!-- Search box -->
                    <div class="col-lg-5 col-xl-6 order-lg-first">
                        <div class="search-form pt-30 pt-lg-0">
                            <form class="form-inline position-relative">
                                <input class="form-control theme1-border" type="search" placeholder="Enter your search key"/>
                                <button class="btn search-btn theme-bg btn-rounded" type="submit">
                                    <i class="icon-magnifier"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Yellow navbar -->

        <!-- White navbar sticky -->
        <nav id="sticky" class="header-bottom theme1 d-none d-lg-block">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-10 offset-lg-2 d-flex flex-wrap align-items-center position-relative">
                        <ul class="main-menu d-flex">
                            <li class="active ml-0">
                                <a href="javascript:void(0)" class="pl-0">Home</a>
                            </li>
                            <li><a href="javascript:void(0)">About Us</a></li>
                            <li><a href="javascript:void(0)">Our Menu</a></li>
                            <li><a href="javascript:void(0)">Contact Us</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
        <!-- White navbar sticky -->
    </header>
</template>

<script>
    import {
        BLink,
        BNavbarNav,
        BNavItem
    } from 'bootstrap-vue'
    import VuexyLogo from "@core/layouts/components/Logo.vue";
    import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
    import CartDropdown from '@/layouts/components/CartDropdown.vue'
    import FeatherIcon from '../../../@core/components/feather-icon/FeatherIcon.vue';
    export default {
        components: {
            BLink,
            BNavbarNav,
            BNavItem,
            VuexyLogo,
            DarkToggler,
            CartDropdown,
            FeatherIcon,
        },
        created() {},
        mounted() {},
        data() {
            return {
                showCart: true
            }
        },
        watch: {},
        methods: {}
    }
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-auth.scss";

.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>