<template>
    <div>
        <landing-navbar/>
        <section class="container-fluid">
            <menu-grid :isFromLanding="true"/>
        </section>
    </div>
</template>

<script>
    import { BOverlay } from 'bootstrap-vue'
    import LandingNavbar from './LandingNavbar.vue'
    import MenuGrid from '@/layouts/components/MenuGrid.vue'
    import Menu from '@/libs/services/menu'
    import app from '@/libs/app'
    export default {
        components: {
            BOverlay,
            LandingNavbar,
            MenuGrid,
        },
        beforeCreate() {
            // window.location.replace(app.baseLanding)
        },
        mounted() {},
        data() {
            return {
                menu: new Menu,
                items: [],
                show: true,
            }
        },
        methods: {
        }
    }
</script>

<style scoped>
@import '../../../assets/scss/custom/style.css';
@import '../../../assets/scss/custom/style.min.css';
</style>