<template>
    <div>
        <b-overlay :show="show" variant="transparent" blur="2px" no-wrap></b-overlay>
        <!-- class="p-0 d-flex flex-wrap justify-content-start" -->
        <b-row class="p-0 d-flex flex-wrap justify-content-start">
            <div class="col-12 col-sm-6 col-md-4" v-for="item in items" :key="item.id">
                <div class="card product-card">
                    <div class="card-body p-0">
                        <div class="media flex-column">
                            <!-- Product Thumbnail container -->
                            <div class="product-thumbnail position-relative">
                                <!-- Product Thumbnail -->
                                <a href="javascript:void(0)">
                                    <b-img-lazy :src="item.image" class="first-img rounded" fluid/>
                                </a>

                                <!-- Product controls -->
                                <ul class="product-links d-flex justify-content-center">
                                    <li>
                                        <a href="javascript:void(0)">
                                            <span data-toggle="tooltip" data-placement="bottom" title="Add to Favorites" class="icon-heart"></span>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="javascript:void(0)">
                                            <span data-toggle="tooltip" data-placement="bottom" title="View item" class="icon-magnifier"></span>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <!-- Product detail container -->
                            <div class="media-body">
                                <div class="product-desc">
                                    <h3 class="title">
                                        <a href="javascript:void(0)">{{item.name}}</a>
                                    </h3>

                                    <div class="d-flex align-items-center justify-content-between">
                                        <h6 class="product-price">&#8369;{{item.price}}</h6>
                                        <button class="pro-btn" data-toggle="modal"><i class="icon-basket"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
            <!-- <div class="col-12 col-sm-5 col-md-3 col-lg-2">
                <div class="tab-content" id="pills-tabContent">
                    <div class="product-slider-init theme1 slick-nav">
                        <div class="slider-item d-flex flex-row flex-wrap">
                                    
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- LEGACY PRODUCT CARDS -->
            <!-- <div class="equal-column col-12 col-sm-6 col-md-4 col-lg-3" v-for="item in items" :key="item.id">
                <b-card
                    :img-src="item.image"
                    :img-alt="item.name + ' Image'"
                    img-top
                    :title="item.name"
                    class="p-0 container-fluid ecommerce-card "
                >
                    <b-card-body class="p-0 my-2">
                        <b-card-text v-if="item.desc">
                            {{ item.desc }}
                        </b-card-text>
                    </b-card-body>

                    <b-card-footer>
                        <b-col class="align-items-center justify-content-center d-flex flex-column">
                            <b-card-text>
                                <span class="font-weight-bold" v-if="item.status == 'active'">&#8369;{{ item.price }}</span>
                                <span class="font-weight-bold text-capitalize text-danger" v-if="item.status == 'sold out'">{{ item.status }}</span>
                                <span class="font-weight-bold text-muted" v-if="item.status == 'on sale'"><del>{{ item.price }}</del></span>
                                <span class="font-weight-bold" v-if="item.status == 'on sale'">{{ item.rrp }}</span>
                                <span class="font-weight-bold text-capitalize" v-if="item.status == 'for pricing'"><strong>{{ item.status }}</strong></span>
                            </b-card-text>

                            <b-overlay :show="showBtnLoader && clickedBtn == item.id" rounded="sm" spinner-variant="primary" spinner-small class="d-inline-block">
                                <b-button
                                v-ripple.400="'rgba(113,102,240,0.15)'"
                                v-b-tooltip.hover.v-dark
                                variant="primary"
                                :id="item.id"
                                :disabled="item.status == 'sold out'"
                                v-if="item.status != 'for pricing'"
                                @click="addItemToCart(item)">
                                    Add to Cart
                                </b-button>
                            </b-overlay>
                            <b-tooltip v-if="item.status != 'for pricing'" :target="(item.id).toString()" :delay="delay" placement="top">
                                Click to add {{ item.name }} to cart
                            </b-tooltip>
                        </b-col>
                    </b-card-footer>
                </b-card>
            </div> -->
        </b-row>
    </div>
</template>

<script>
    import { BRow, BCol, BButton, BCard, BCardBody, BCardFooter, BCardGroup, BCardSubTitle, BCardText, BLink, VBTooltip, BTooltip, BOverlay, BImgLazy } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import Cart from '../../libs/controllers/cart-control'
    import store from '@/store/index'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import Menu from '@/libs/services/menu'
    import useJwt from "@/auth/jwt/useJwt"
    import app from '@/libs/app'

    export default {
        props: {
            isFromLanding: Boolean,
        },
        components: {
            BRow,
            BCol,
            BButton,
            BCard,
            BCardBody,
            BCardFooter,
            BCardGroup,
            BCardText,
            BLink,
            BTooltip,
            BCardSubTitle,
            BOverlay,
            BImgLazy,
        },
        directives: {
            Ripple,
            'b-tooltip': VBTooltip
        },
        data() {
            return {
                items: [],
                cart: new Cart,
                menu: new Menu,
                delay: { show:500, hide: 100 },
                show: true,
                showBtnLoader: false,
                clickedBtn: ''
            }
        },
        created() {
            setTimeout(() => {
                this.preloadMenu()
            }, 100)
        },
        mounted() {
        },
        methods: {
            async preloadMenu() {
                // Fork here if getting items from the landing page
                // or inside the web app.
                if (this.isFromLanding) {
                    await this.menu.getProducts().then(response => {
                        let res = response
                        if (res.result) {
                            let data = res.data ? res.data : []
                            if (data.length > 0) {
                                this.items = res.data
                            }
                        }
                        else {
                            console.log('Error getting items: ', res)
                        }

                        this.show = false
                    }).catch(ex => {
                        console.log('Error requesting for public menu items: ', ex)
                    })
                }
                else {
                    this.$store.dispatch('getMenuItems').then(response => {
                        let res = response.data
                        if (res.result) {
                            let data = res.data ? res.data : []
                            if (data.length > 0) {
                                this.items = data
                                this.show = false

                                // Persist the data in the store.
                                this.$store.commit('STORE_MENU_ITEMS', data)
                            }
                            else {
                                console.log(res.message)
                            }
                        }
                        else {
                            console.log('Error getting menu items: ', res)
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Cannot get Cart right now',
                                    icon: 'ShoppingCartIcon',
                                    variant: 'danger',
                                    text: res.message
                                }
                            })
                        }
                    }).catch(ex => {
                        console.log('Error dispatching getMenuItems action: ', ex)
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Cannot get Menu Items right now',
                                icon: 'ShoppingCartIcon',
                                variant: 'danger',
                                text: ex
                            }
                        })
                    })
                }
            },
            async addItemToCart(item) {
                this.toggleBtnLoader(true, item.id)
                // Store action data
                let data = null
                let dispatchAction = ''

                // Determine first if the item being added is already in the cart.
                let currentCart = this.$store.getters.getCart
                let matchedItem = null
                currentCart.forEach(cartItem => {
                    // Loop inside the cart to check for matches.
                    // Currently matching names; proposing to add product ID to cart response.
                    if (cartItem['product_id'] == item.id) {
                        matchedItem = cartItem
                    }
                })

                // Proceed with add or update action.
                if (matchedItem) {
                    // Matching item found in cart
                    dispatchAction = 'updateItemInCart'
                    data = {id: matchedItem.id, quantity: matchedItem.quantity + 1}
                }
                else {
                    // No match found
                    dispatchAction = 'addItemToCart'
                    data = {id: item.id, quantity: 1}
                }

                if (dispatchAction != '' && data) {
                    this.$store.dispatch(dispatchAction, data).then(response => {
                        if (response.data.result) {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Item added to cart',
                                    icon: 'ShoppingCartIcon',
                                    variant: 'success',
                                    text: 'Added ' + item.name + ' to cart.'
                                },
                            })
                        }
                        else {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Failed to add item to cart',
                                    icon: 'ShoppingCartIcon',
                                    variant: 'danger',
                                    text: response.data.message
                                }
                            })
                        }
                        this.toggleBtnLoader(false, '')
                    }).catch(ex => {
                        console.log('Error dispatching addItemToCart action: ', ex)
                        this.toggleBtnLoader(false, '')
                    })
                }
            },
            toggleBtnLoader(showLoader, id) {
                this.showBtnLoader = showLoader
                this.clickedBtn = id
            }
        }
    }
</script>

<style scoped>
@font-face {
  font-family: "Gilroy-Bold ☞";
  font-style: normal;
  font-weight: 700;
  src: local("Gilroy-Bold ☞"), url("../../assets/fonts/Gilroy-Bold.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Heavy ☞";
  font-style: normal;
  font-weight: 900;
  src: local("Gilroy-Heavy ☞"), url("../../assets/fonts/Gilroy-Heavy.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Light ☞";
  font-style: normal;
  font-weight: 300;
  src: local("Gilroy-Light ☞"), url("../../assets/fonts/Gilroy-Light.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Medium ☞";
  font-style: normal;
  font-weight: 500;
  src: local("Gilroy-Medium ☞"), url("../../assets/fonts/Gilroy-Medium.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Regular ☞";
  font-style: normal;
  font-weight: 400;
  src: local("Gilroy-Regular ☞"), url("../../assets/fonts/Gilroy-Regular.woff") format("woff");
}

.product-links {
  position: absolute;
  z-index: 3;
  width: 100%;
  bottom: 15px;
}

.product-links li {
  margin: 0 3px;
}

.product-links li a {
  display: block;
  background: #f6f6f6;
  color: #1d1d1d;
  width: 40px;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  -webkit-transform: scale3d(0, 0, 0);
          transform: scale3d(0, 0, 0);
  border-radius: 50%;
}

.product-card .card-body:hover .product-links li a {
  -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
}

.product-card .card-body:hover .product-links li:nth-child(2) a {
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
}

.product-card .card-body:hover .product-links li:nth-child(3) a {
  -webkit-transition: all 0.9s;
  transition: all 0.9s;
}

.theme1 .product-links li:hover a {
  background: #f33535;
  color: #ffffff;
}

.theme2 .product-links li:hover a {
  background: #ff7519;
  color: #ffffff;
}

.theme3 .product-links li:hover a {
  background: #10a341;
  color: #ffffff;
}

.product-thumbnail img {
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}

img.second-img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}

.product-card {
  border: 0;
}

.product-card:hover {
  -webkit-box-shadow: 0 0 11.83px 1.17px rgba(0, 0, 0, 0.06);
          box-shadow: 0 0 11.83px 1.17px rgba(0, 0, 0, 0.06);
}

.product-card:hover img.second-img {
  opacity: 1;
  z-index: 1;
}

.product-card.no-shadow {
  -webkit-box-shadow: none;
          box-shadow: none;
}

/* product desc style */
.product-desc {
  padding: 20px 10px 15px 10px;
}

.product-desc .title {
  line-height: 1.4;
  margin-bottom: 10px;
  color: #707070;
  font-size: 15px;
  text-transform: capitalize;
  font-family: "Gilroy-Regular ☞";
  font-weight: 400;
}

.theme1 .product-desc .title:hover a {
  color: #f33535;
}

.theme2 .product-desc .title:hover a {
  color: #ff7519;
}

.theme3 .product-desc .title:hover a {
  color: #10a341;
}

.star-rating span {
  font-weight: 400;
  line-height: 1;
  margin: 0 1px 0 0;
  font-size: 18px;
  color: #fdd835;
}

.star-rating span .de-selected {
  color: #eee;
}

.product-price {
  color: #1d1d1d;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  font-family: "Gilroy-Bold ☞";
}

.product-price .del {
  color: #9b9b9b;
  font-weight: 400;
  margin-right: 2px;
  line-height: 20px;
  font-size: 15px;
}

.theme1 .product-price .onsale {
  color: #f33535;
}

.theme2 .product-price .onsale {
  color: #ff7519;
}

.theme3 .product-price .onsale {
  color: #10a341;
}

.pro-btn {
  background: #f6f6f6;
  color: #1d1d1d;
  width: 40px;
  height: 40px;
  text-align: center;
  font-weight: 400;
  line-height: 40px;
  font-size: 16px;
  border-radius: 50%;
}

.pro-btn-right {
  position: absolute;
  right: 20px;
}

.theme1 .pro-btn:hover {
  background: #f33535;
  color: #ffffff;
}

.theme2 .pro-btn:hover {
  background: #ff7519;
  color: #ffffff;
}

.theme3 .pro-btn:hover {
  background: #10a341;
  color: #ffffff;
}
</style>