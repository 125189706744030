import httpConfigHelper from './config.js'
import app from '../app'

export default class Menu {
    httpConfig = new httpConfigHelper
    
    // We won't be using Axios in any of the requests for public use as these requests are 
    // intercepted by the JWT classes and are overridden. To get around this, a custom
    // fetch with custom headers is set in place to facilitate endpoint requests.
    async getProducts() {
        var headers = {'App-Action-Header': app.productList}
        var url = this.httpConfig.urlBuilder(app.public)
        return new Promise(resolve => {
            this.httpConfig.fetchRequest(url, {}, headers).then(response => {
                resolve(response)
            }).catch(ex => {
                console.log('Unable to fetch items: ', ex)
                resolve({result: false, message: ex})
            })
        })
    }

    async getCart(data) {
        const httpOptions = this.httpConfig.headerConfig('ViewCart')
        const url = this.httpConfig.urlBuilder(app.public)
        return {url: url, header: httpOptions}
    }
}